<template>
  <div class="event-panel" @click="onTriggerClick">
    <div class="reject-tag" v-if="reject">驳</div>
    <div class="panel-header">
      <div class="heade-index">
        <van-tag plain round type="success">{{ index }}</van-tag>
        <!-- <slot name="index"></slot> -->
      </div>

      <div class="header-title">{{ title }}</div>
      <div class="header-extra">
        <slot name="extra"></slot>
      </div>
    </div>
    <div class="panel-content">
      <div class="panel-main">{{ enterprise }}</div>
      <div class="panel-desc">
        <span>{{ point }}</span>
        <span>{{ time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    enterprise: {
      type: String,
      default: "",
    },
    point: {
      type: String,
      default: "",
    },
    time: {
      type: String,
      default: "",
    },
    reject: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 触发点击事件
    onTriggerClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="less" scoped>
.event-panel {
  padding: 10px 15px;
  background: #fff;
  margin: 10px 0px;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    display: inline-block;
    width: 100%;
    left: 0px;
    top: -1px;
    height: 1px;
    background: #dddddd;
    position: absolute;
  }
  &::after {
    content: "";
    display: inline-block;
    width: 100%;
    left: 0px;
    bottom: -1px;
    height: 1px;
    background: #dddddd;
    position: absolute;
  }
  .reject-tag {
    position: absolute;
    left: -20px;
    top: -20px;
    transform: rotate(-45deg);
    width: 40px;
    height: 40px;
    line-height: 65px;
    background-color: #ee0a24;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }
  .panel-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    line-height: 30px;
    padding-bottom: 5px;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      display: inline-block;
      height: 1px;
      background: #cfdde9;
      left: 0px;
      bottom: -1px;
    }
    .header-title {
      font-size: 17px;
      color: #378aed;
      flex: 1;
    }
    .heade-index {
      // flex-basis: content;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .panel-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .panel-main {
      font-size: 16px;
      color: #333333;
      line-height: 35px;
    }
    .panel-desc {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #666666;
      line-height: 14px;
    }
  }
}
</style>
