import { render, staticRenderFns } from "./event-panel.vue?vue&type=template&id=fb83915a&scoped=true&"
import script from "./event-panel.vue?vue&type=script&lang=js&"
export * from "./event-panel.vue?vue&type=script&lang=js&"
import style0 from "./event-panel.vue?vue&type=style&index=0&id=fb83915a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb83915a",
  null
  
)

export default component.exports